import * as React from "react";

import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { about, content, contentFlex, image, instagram, puppies } from './index.module.scss';

import { Link } from "gatsby";
import { FaInstagram } from "react-icons/all";
import BottomSpace from "../components/bottomSpace";

import Zoom from '../components/Zoom';

const IndexPage = () => {
    return (
        <Layout pageTitle="Hem" largeHero={true}>
            <div className={content}>
                <div className={puppies}>
                    <p>
                        🐾🐾 Pudelvalpar födda, hanar letar hem 🐾🐾
                    </p>
                </div>
                <div className={contentFlex}>
                    <div className={about}>
                        <h1>Om oss</h1>
                        <p><i>
                            Med fokus på att föda upp friska och rastypiska hundar som fungerar i hemmet likvärt på tävlingsbanorna.
                        </i></p>
                        <p>
                            Mitt namn är Louise Amsen och jag bor tillsammans med min man och våra tre barn på norra Öland. I hela mitt liv har hund varit en naturlig del av vardagen och när jag skaffade min första renrasiga hund i tonåren så öppnade sig en ny del av hundvärlden för mig där tävlingsgrenarna lydnad och utställning fick störst fokus. Min tanke med hund har alltid handlat om att det ska vara roligt och kännas lättsamt.
                        </p>
                        <p>
                            Här hemma är hundarna en naturlig del av vår familj och följer med på alla våra äventyr i vardagen på gården. I dag är utställning, rallylydnad och agility det som intresserar mig mest men hundvärlden är ett evigt upptäckande och jag lär mig hela tiden nya saker. 🐾
                        </p>
                        <p>
                            Kennelnamnet Sagotassens registrerades och godkändes 2021. Året 2022 genomförde jag SKKs uppfödarutbildning och följer därmed Svenska Kennelklubbens regler och riktlinjer som utgår ifrån centrala bestämmelser kring hundhållning, avel och uppfödning.
                        </p>
                    </div>

                    <Zoom wrapperClass={image}>
                        <StaticImage loading="eager" src={'../images/icon.png'} alt={"En av våra valpar!"} />
                    </Zoom>

                </div>

                <Link to={"https://www.instagram.com/sagotassenskennel/"} style={{ paddingLeft: "5%", paddingRight: "5%", marginTop: "36px", display: "flex", justifyContent: "center", color: "#000" }}>
                    <FaInstagram className={instagram} />
                    <h3 style={{ margin: 0, marginLeft: "16px", alignSelf: "center" }}>Följ oss på Instagram</h3>
                </Link>
            </div>

            <BottomSpace />
        </Layout>
    )
}

export default IndexPage